import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApiService, MonitoringService } from '~/core';
import { AsyncStateType } from './async-state';
import { loadUser, loadUserError, loadUserSuccess } from './user.store';

const initialState = {
  wishlist: [] as string[],
  callState: 'loading' as AsyncStateType,
};

export type WishlistState = typeof initialState;

export const addFormationToWishlist = createAction(
  '[Wishlist Store] Add Formation To Wishlist',
  props<{ formationId: string; originalWishlist: string[] }>()
);
export const addFormationToWishlistSuccess = createAction('[Wishlist Store] Add Formation To Wishlist Success');
export const addFormationToWishlistError = createAction(
  '[Wishlist Store] Add Formation To Wishlist Error',
  props<{ originalWishlist: string[] }>()
);
export const undoAddFormationToWishlist = createAction(
  '[Wishlist Store] Undo Add Formation To Wishlist',
  props<{ originalWishlist: string[] }>()
);

export const removeFormationFromWishlist = createAction(
  '[Wishlist Store] Remove Formation From Wishlist',
  props<{ formationId: string; originalWishlist: string[] }>()
);
export const removeFormationFromWishlistSuccess = createAction(
  '[Wishlist Store] Remove Formation From Wishlist Success',
  props<{ wishlist: string[]; notify?: boolean }>()
);
export const removeFormationFromWishlistError = createAction(
  '[Wishlist Store] Remove Formation From Wishlist Error',
  props<{ originalWishlist: string[] }>()
);
export const undoRemoveFormationFromWishlist = createAction(
  '[Wishlist Store] Undo Remove Formation From Wishlist',
  props<{ originalWishlist: string[] }>()
);

export const clearWishlist = createAction('[Wishlist Store] Clear Wishlist');

export const wishlistReducer = createReducer<WishlistState | undefined>(
  initialState,
  on(loadUser, () => initialState),
  on(loadUserSuccess, (state, { user }) => ({ ...state, wishlist: user.wishlist, callState: 'loaded' })),
  on(loadUserError, (state) => ({ ...state, callState: 'error' })),
  on(addFormationToWishlist, (state, { formationId }) => ({ ...state, wishlist: [...state.wishlist, formationId] })),
  on(undoAddFormationToWishlist, (state, { originalWishlist }) => ({ ...state, wishlist: originalWishlist })),
  on(removeFormationFromWishlist, (state, { formationId }) => ({
    ...state,
    wishlist: state.wishlist.filter((id) => id !== formationId),
  })),
  on(removeFormationFromWishlistSuccess, (state, { wishlist }) => ({ ...state, wishlist })),
  on(undoRemoveFormationFromWishlist, (state, { originalWishlist }) => ({ ...state, wishlist: originalWishlist })),
  on(clearWishlist, () => initialState)
);

@Injectable()
export class WishlistEffects {
  addFormationToWishlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFormationToWishlist),
      switchMap(({ formationId, originalWishlist }) =>
        this.api.addFormationToWishList(formationId).pipe(
          map(() => addFormationToWishlistSuccess()),
          catchError((error) => {
            this.monitoring.logException(error);
            return of(addFormationToWishlistError({ originalWishlist }));
          })
        )
      )
    )
  );

  addFromationToWishlistSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addFormationToWishlistSuccess),
        tap(() => {
          this.snackBar.open(
            $localize`:@@wishlist-5:La formation a été ajoutée à votre liste de souhaits avec succès.`,
            null,
            {
              duration: 5000,
            }
          );
        })
      ),
    { dispatch: false }
  );

  addFromationToWishlistError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFormationToWishlistError),
      map(({ originalWishlist }) => {
        this.snackBar.open(
          $localize`:@@wishlist-6:Une erreur est survenue lors de l'ajout de la formation à votre liste de souhaits.`,
          null,
          {
            duration: 5000,
          }
        );
        return undoAddFormationToWishlist({ originalWishlist });
      })
    )
  );

  removeFormationFromWishlist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFormationFromWishlist),
      switchMap(({ formationId, originalWishlist }) =>
        this.api.removeFormationFromWishList(formationId).pipe(
          map((wishlist) => removeFormationFromWishlistSuccess({ wishlist })),
          catchError((error) => {
            this.monitoring.logException(error);
            return of(removeFormationFromWishlistError({ originalWishlist }));
          })
        )
      )
    )
  );

  removeFromationFromWishlistSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeFormationFromWishlistSuccess),
        tap(({ notify = true }) => {
          if (notify) {
            this.snackBar.open(
              $localize`:@@wishlist-7:La formation a été retirée de votre liste de souhaits avec succès.`,
              null,
              {
                duration: 5000,
              }
            );
          }
        })
      ),
    { dispatch: false }
  );

  removeFromationFromWishlistError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFormationFromWishlistError),
      map(({ originalWishlist }) => {
        this.snackBar.open(
          $localize`:@@wishlist-8:Une erreur est survenue lors du retrait de la formation de votre liste de souhaits.`,
          null,
          {
            duration: 5000,
          }
        );
        return undoRemoveFormationFromWishlist({ originalWishlist });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private monitoring: MonitoringService,
    private snackBar: MatSnackBar
  ) {}
}

export const selectWishlistState = createFeatureSelector<WishlistState>('wishlist');
export const selectWishlist = createSelector(selectWishlistState, (state) => state.wishlist);
export const selectWishlistCallState = createSelector(selectWishlistState, (state) => state.callState);
