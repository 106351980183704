import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { User } from '~/models';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ApiService, MonitoringService } from '~/core';
import { AsyncStateType } from './async-state';

const initialState = {
  user: null as User,
  callState: 'loading' as AsyncStateType,
};

export type UserState = typeof initialState;

export const loadUser = createAction('[User Store] Load User');
export const loadUserSuccess = createAction('[User Store] Load User Success', props<{ user: User }>());
export const loadUserError = createAction('[User Store] Load User Error');

export const updateUser = createAction('[User Store] Update User', props<{ user: User; originalUser: User }>());
export const updateUserSuccess = createAction('[User Store] Update User Success');
export const updateUserError = createAction('[User Store] Update User Error', props<{ originalUser: User }>());
export const undoUpdateUser = createAction('[User Store] Undo Update User', props<{ originalUser: User }>());

export const clearUser = createAction('[User Store] Clear User');

export const userReducer = createReducer<UserState | undefined>(
  initialState,
  on(loadUser, () => initialState),
  on(loadUserSuccess, (state, { user }) => ({ ...state, user, callState: 'loaded' })),
  on(loadUserError, (state) => ({ ...state, callState: 'error' })),
  on(updateUser, (state, { user }) => ({ ...state, user })),
  on(undoUpdateUser, (state, { originalUser: user }) => ({ ...state, user })),
  on(clearUser, () => initialState)
);

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUser),
      switchMap(() =>
        this.api.getMyUser().pipe(
          map((user) => loadUserSuccess({ user })),
          catchError(() => of(loadUserError()))
        )
      )
    )
  );

  loadUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserSuccess),
        tap(({ user }) => {
          this.monitoring.setOrClearAuthenticatedUserContext(user);
        })
      ),
    { dispatch: false }
  );

  loadUserError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserError),
        tap(() => {
          this.monitoring.setOrClearAuthenticatedUserContext();
        })
      ),
    { dispatch: false }
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUser),
      switchMap(({ user, originalUser }) =>
        this.api.saveUserProfile(user).pipe(
          map(() => updateUserSuccess()),
          catchError((error) => {
            this.monitoring.logException(error);
            return of(updateUserError({ originalUser }));
          })
        )
      )
    )
  );

  updateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserSuccess),
        tap(() => {
          this.snackBar.open($localize`:@@user-store-1:Votre profile a été sauvegardé avec succès.`, null, {
            duration: 5000,
          });
        })
      ),
    { dispatch: false }
  );

  updateUserError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserError),
      map(({ originalUser }) => {
        this.snackBar.open(
          $localize`:@@user-store-2:Une erreur est survenue lors de la sauvegarde du votre profile.`,
          null,
          {
            duration: 5000,
          }
        );
        return undoUpdateUser({ originalUser });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private monitoring: MonitoringService,
    private snackBar: MatSnackBar
  ) {}
}

export const selectUserState = createFeatureSelector<UserState>('user');
export const selectUser = createSelector(selectUserState, (state) => state.user);
export const selectUserCallState = createSelector(selectUserState, (state) => state.callState);
